(function() {
    'use strict';

    angular
        .module('capacitacionCertificacionApp')
        .controller('CertificationCourseController', CertificationCourseController);

    CertificationCourseController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', '$sce', 'entity', 'User', '$state','JhiLanguageService'];

    function CertificationCourseController($scope, $rootScope, $stateParams, previousState, $sce, entity, User, $state, JhiLanguageService) {
        var vm = this;

        if (!User.current) {
            $state.go('certification.enrollment');
        } else {
            if(User.current.testInit) {
                $state.go('certification.questionnaire');
            }

            // This should be load from server from course's document.
            var videos = {
                'mejillones': {},
                'san_antonio': {}
            };

            JhiLanguageService.getCurrent().then(function (language) {
                vm.video = {
                    sources: [
                        {src: $sce.trustAsResourceUrl(videos[User.current.terminal][language]), type: "video/mp4"},
                    ]
                };
            });
        }
        vm.certificate = entity;
        vm.previousState = previousState.name;
    }
})();
